import React, { useState, useEffect } from "react"
import { Link, graphql, navigate } from "gatsby"

import Seo from "../components/seo"
import BackButton from "../components/backButton"
import Localize from "../components/localize"

import { TeamSvg } from "../svgs/svg"

import EmployeeImage from "../../static/images/user.png"
import { RightArrow } from "../shared/icons/icons"

const Team = ({
  data: { teas, info, services, site, experts },
  pageContext,
  location,
}) => {
  const [teams, setTeams] = useState([])

  const shuffle = teams => {
    for (let i = teams.length - 1; i >= 0; i--) {
      let randomIndex = Math.floor(Math.random() * (i + 1))
      let itemAtIndex = teams[randomIndex]
      teams[randomIndex] = teams[i]
      teams[i] = itemAtIndex
    }
    return teams
  }

  useEffect(() => {
    setTeams(
      shuffle(
        location.state
          ? location.state.teams
            ? location.state.teams
            : teas.edges
          : teas.edges
      )
    )
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true })
    }
  }, [])
  useEffect(() => {
    setTimeout(() => {
      if (location.state && location.state.teams && location.state.back) {
        window.scrollTo({
          top: location.state.scroll,
          left: 0,
          behavior: "smooth",
        })
      }
    })
  }, [location.state])

  const lan = pageContext.locale
  const [scrollPosition, setScrollPosition] = useState(0)
  const [profileName, setProfileName] = useState(
    location.state
      ? location.state.profileName
        ? location.state.profileName
        : ""
      : ""
  )
  const [field, setField] = useState(
    location.state ? (location.state.field ? location.state.field : "") : ""
  )
  const [expertise, setExpertise] = useState(
    location.state
      ? location.state.expertise
        ? location.state.expertise
        : ""
      : ""
  )
  const team = info
  const allTeams = teas.edges
  const allServices = services.edges
  const allExperts = experts.edges

  const handleScroll = () => {
    var doc = document.documentElement
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    setScrollPosition(top)
  }

  const onGo = () => {
    const filteredTeams = []
    allTeams.forEach(team => {
      if (
        (profileName
          ? team.node.name.toLowerCase().includes(profileName)
          : true) &&
        (field
          ? team.node.main_service_type.find(
              serviceType => serviceType.title === field
            )
            ? true
            : false
          : true) &&
        (expertise
          ? team.node.expertise.find(
              nodeExpertise => nodeExpertise.title === expertise
            )
            ? true
            : false
          : true)
      ) {
        filteredTeams.push(team)
      }
    })
    setTeams(filteredTeams)
  }

  const onReset = () => {
    setProfileName("")
    setField("")
    setExpertise("")
    setTeams(allTeams)
  }

  const onChange = (event, name) => {
    const value =
      name === "name" ? event.target.value.toLowerCase() : event.target.value
    if (name === "name") {
      setProfileName(value.replace(/^\s+/g, ""))
    } else if (name === "field") {
      setField(value)
    } else {
      setExpertise(value)
    }
  }

  return (
    <>
      <Seo
        lan={lan}
        title="Team"
        description={
          lan === "en"
            ? "Always the right contact person. With us, competent and experienced experts, lawyers, notaries and consultants are at your side in all specialist areas. Tell us about your challenges and we will provide the best solution."
            : "Immer der richtige Ansprechpartner. Bei uns stehen Ihnen in allen Fachbereichen kompetente und erfahrene Experten, Anwälte, Notare und Berater zur Seite. Erzählen Sie uns von Ihren Herausforderungen und wir finden die beste Lösung."
        }
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        titleTemplate={
          lan === "en"
            ? "Team: HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "Team: HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />
      <BackButton
        to={`/${lan}/`}
        lan={lan}
        text={lan === "en" ? "Home" : "Startseite"}
      />
      <div className="header h-auto no--margin-menu header-typ2 about--us-header">
        <TeamSvg />
        <div
          id="headerText"
          className="headerText auto-adjust-header about--us-text about-text"
        >
          <h1 className="m-0">{team.team_title}</h1>
          {team.team_description.map(tea => {
            return tea.children.map((te, index) => {
              return <p key={index}>{te.text}</p>
            })
          })}
          <Link
            to={`/${pageContext.locale}/contact/`}
            className="button contact"
          >
            <div></div>
            <span className="icon-arrow_right">
              <RightArrow />
            </span>
            {lan && site.siteMetadata && site.siteMetadata[lan].contactUs}
          </Link>
        </div>
      </div>
      <div className="teammember-search">
        <div>
          <div>
            <input
              className="search-dr"
              value={profileName}
              id="namefilter"
              style={{
                padding: "0 50px 0 20px",
              }}
              onKeyDown={event => {
                if (event.key === "Enter" && !event.shiftKey) {
                  onGo()
                }
              }}
              onChange={event => {
                onChange(event, "name")
              }}
              type="text"
              placeholder={
                lan &&
                site.siteMetadata &&
                site.siteMetadata[lan].searchForNames
              }
            />
          </div>
        </div>
        <div>
          <div className="search dropdown">
            <select
              id="divisionfilter"
              value={field}
              className="team-dr p-0-35-0-20"
              onChange={event => onChange(event, "field")}
              onBlur={event => onChange(event, "field")}
            >
              <option value="">
                {lan && site.siteMetadata && site.siteMetadata[lan].selectField}
              </option>
              {allServices.map((serv, index) => {
                return (
                  <option
                    className="cursor-pointer"
                    key={index}
                    value={serv.node.title}
                  >
                    {serv.node.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="expertise-filter">
          <div className="search dropdown">
            <select
              id="divisionfilter"
              className="team-dr p-0-35-0-20"
              value={expertise}
              onChange={event => onChange(event, "expertise")}
              onBlur={event => onChange(event, "expertise")}
            >
              <option value="">
                {lan &&
                  site.siteMetadata &&
                  site.siteMetadata[lan].selectExpertise}
              </option>
              {allExperts.map((exp, index) => {
                return <option key={index}>{exp.node.title}</option>
              })}
            </select>
            {/* <DropdownArrow /> */}
          </div>
        </div>
        <button
          onClick={onGo}
          className="news-submit-button ml--15 mt-20 transition-all"
        >
          {lan === "en" ? "Search" : "Suche"}
        </button>
        <button
          className="news-submit-button ml--15 reset-button"
          onClick={onReset}
        >
          {lan === "en" ? "Reset" : "Zurücksetzen"}
        </button>
      </div>
      <div className="teammember noshadow res-team">
        {teams.length > 0 &&
          teams.map((team, index) => {
            return (
              <div key={index} className="team-con">
                <div className="team-image" key={index}>
                  <img
                    loading="lazy"
                    alt="imgg"
                    className="h-inherit w-100 object-fit-cover"
                    src={
                      team.node.mainImage
                        ? team.node.mainImage.asset
                          ? `${team.node.mainImage.asset.url}?h=350`
                          : EmployeeImage
                        : EmployeeImage
                    }
                  />
                </div>
                <div className="hover-div">
                  {team.node.name}
                  <br />
                  <button
                    onClick={() => [
                      window.sessionStorage.setItem("text-en", "Team"),
                      window.sessionStorage.setItem("text-de", "Team"),
                      window.sessionStorage.setItem("route-en", `/en/team/`),
                      window.sessionStorage.setItem("route-de", `/de/team/`),
                      navigate(`/${lan}/team/${team.node.slug.current}/`, {
                        state: {
                          profileName: profileName,
                          field: field,
                          back: true,
                          scroll: scrollPosition,
                          expertise: expertise,
                          teams: teams,
                        },
                      }),
                    ]}
                  >
                    {lan &&
                      site.siteMetadata &&
                      site.siteMetadata[lan].goToProfile}
                  </button>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        en {
          goToProfile
          contactUs
          selectField
          selectExpertise
          searchForNames
        }
        de {
          goToProfile
          contactUs
          selectField
          selectExpertise
          searchForNames
        }
      }
    }
    teas: allSanityTeamProfile {
      edges {
        node {
          expertise {
            title {
              _type
              en
              de
            }
          }
          name
          slug {
            current
          }
          main_service_type {
            title {
              _type
              en
              de
            }
          }
          mainImage {
            asset {
              url
            }
            alt
          }
        }
      }
    }
    experts: allSanityExpert {
      edges {
        node {
          title {
            _type
            en
            de
          }
        }
      }
    }
    info: sanityStatic {
      team_title {
        _type
        en
        de
      }
      team_description {
        _type
        en {
          children {
            text
          }
        }
        de {
          children {
            text
          }
        }
      }
    }
    services: allSanityMainService(sort: { fields: order, order: ASC }) {
      edges {
        node {
          title {
            _type
            de
            en
          }
          order
        }
      }
    }
  }
`

export default Localize(Team)
